/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// COMPONENTS
// import Navbar from "../Shared/Navbar";
import Header from "../Shared/header";
import Footer from "../Shared/Footer";
import styles from "./Landing.module.css";

// IMAGES && ICONS
import HeroOne from "../../assets/hero-1.svg";

const ServiceSection = ({ title, options }) => {
  console.log("object");
  return (
    <div id={`${title}`} className="flex flex-col mb-8">
      <p className="font-bookmeBold text-3xl mb-8">{title}</p>
      <div className="font-bookmeBook flex flex-wrap">
        {options.map((option) => {
          let goto = `https://client.bookme.com.ng/providers?state=5ebeb7ec7d361c7dca0aac1c&&category=${option}&&location=Lagos`;
          goto = encodeURI(goto);
          return (
            <a
              target="_blank"
              rel="noreferrer"
              href={goto}
              key={option}
              className="w-1/4 pb-4"
            >
              {option}
            </a>
          );
        })}
      </div>
    </div>
  );
};

ServiceSection.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Services = () => {
  const [allCategories, setAllCategories] = useState([]);
  const [categories, setCategories] = useState({});
  const [category, setCategory] = useState("");
  const [view, setView] = useState({});

  /** HANDLE USER INPUT */
  const handleInput = (e) => {
    const { value } = e.target;
    if (value === "") {
      setView(categories);
    }
    setCategory(value);
  };

  /** HANDLE SEARCH BAR SUBMIT */
  const handleSubmit = (e) => {
    e.preventDefault();
    const startingLetter = category[0].toUpperCase();
    setView({ [`${startingLetter}`]: categories[startingLetter] });
  };

  /** CONVERT CATEGORIES TO AN OBJECT WITH KEY VALUE PAIRS */
  const convertCategories = (data) =>
    data.reduce((acc, cur) => {
      const start = cur[0].toUpperCase();
      if (acc[start] === undefined) {
        acc[start] = [cur];
      } else {
        acc[start] = [...acc[start], cur];
      }
      return acc;
    }, {});

  /** HANDLE FETCH CATEGORIES FROM API */
  const getCategories = async () => {
    const fetchCategories = new Promise((resolve, reject) => {
      fetch("https://api.bookme.com.ng/category")
        .then((response) => response.json())
        .then((data) => resolve(data))
        .catch((e) => reject(e));
    });
    let data = await fetchCategories;
    data = data.sort();
    setAllCategories(data);
  };

  /** ON MOUNT FETCH CATEGORIES */
  useEffect(() => {
    getCategories();
  }, []);

  /** AFTER CATEGORIES FETCH ARRANGE DATA */
  useEffect(() => {
    const categoriesObject = convertCategories(allCategories);
    setCategories(categoriesObject);
  }, [allCategories]);

  /** UPDATE THE VIEW AFTER EACH SEARCH */
  useEffect(() => {
    setView(categories);
  }, [categories]);

  const renderCategories = () => {
    const arrayOfCategoryKeys = Object.keys(view);
    return arrayOfCategoryKeys.map((categoryKey) => (
      <ServiceSection
        key={categoryKey}
        title={categoryKey}
        options={view[categoryKey]}
      />
    ));
  };

  return (
    <div className={`flex flex-col ${styles.body}`}>
      {/* <Navbar /> */}
      <Header />
      <section
        style={{ backgroundColor: "#E8ECDD" }}
        className="flex flex-col md:flex-row justify-between px-4 py-4 md:pt-32 md:pb-16 md:px-24 xl:px-48"
      >
        <div className=" w-full md:w-5/12 mb-8 md:mb-0">
          <div className="flex flex-col">
            <p className="font-bookmeBold text-4xl mb-4">
              All available services on BookMe
            </p>
            <p className="font-bookmeBook text-base">
              To book an appointment, click on any of the service listed below
              to view a collection of Providers that offer the service.
            </p>
          </div>
        </div>
        <div className=" w-full md:w-5/12">
          <img src={HeroOne} alt="hero_image" className="w-full h-full" />
        </div>
      </section>

      <section className="flex flex-col px-4 py-4 md:py-10 md:px-24 xl:px-48">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col md:flex-row md:h-20 w-full mb-8"
        >
          <input
            type="text"
            className="border border-gray-500 placeholder-gray-500 text-lg px-8 mb-4 md:mb-0 md:mr-4 flex-grow h-16 md:h-auto"
            placeholder="Search for Services"
            required
            value={category}
            onChange={handleInput}
          />
          <button
            type="submit"
            className="bg-bookme-green py-3 md:py-0 md:px-24 text-white text-lg font-bookmeBook"
            disabled={category.length <= 0}
          >
            Search
          </button>
        </form>
        <div className="flex flex-wrap mb-16">
          <a
            href="#A"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            A
          </a>
          <a
            href="#B"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            B
          </a>
          <a
            href="#C"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            C
          </a>
          <a
            href="#D"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            D
          </a>
          <a
            href="#E"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            E
          </a>
          <a
            href="#F"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            F
          </a>
          <a
            href="#G"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            G
          </a>
          <a
            href="#H"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            H
          </a>
          <a
            href="#I"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            I
          </a>
          <a
            href="#J"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            J
          </a>
          <a
            href="#K"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            K
          </a>
          <a
            href="#L"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            L
          </a>
          <a
            href="#M"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            M
          </a>
          <a
            href="#N"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            N
          </a>
          <a
            href="#O"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            O
          </a>
          <a
            href="#P"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            P
          </a>
          <a
            href="#Q"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            Q
          </a>
          <a
            href="#R"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            R
          </a>
          <a
            href="#S"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            S
          </a>
          <a
            href="#T"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            T
          </a>
          <a
            href="#U"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            U
          </a>
          <a
            href="#V"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            V
          </a>
          <a
            href="#W"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            W
          </a>
          <a
            href="#X"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            X
          </a>
          <a
            href="#Y"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            Y
          </a>
          <a
            href="#Z"
            className="font-bookmeMedium text-sm underline mr-6 mb-2 md:mb-0"
          >
            Z
          </a>
        </div>

        <div className="flex flex-col">{renderCategories()}</div>
      </section>

      {/* <section className="flex flex-col items-center mb-12 px-4 py-4 md:px-24 xl:px-48">
        <div className=" w-11/12 md:w-5/12 text-center">
          <p className=" font-bookmeBold text-3xl mb-8">Subscribe to our Newsletter</p>
          <p className=" text-gray-800 mb-8">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the
          </p>
        </div>
        <div className="md:h-12 flex flex-col md:flex-row w-full justify-center">
          <input
            style={{ border: '1px solid #E1E1E1' }}
            type="text"
            placeholder="Enter your email"
            className=" h-12 mb-4 md:mb-0 md:h-full w-full md:w-1/2"
          />
          <button
            type="button"
            className="bg-bookme-green md:h-full px-4 py-2 md:py-0 text-white"
          >
            Subscribe

          </button>
        </div>
      </section> */}

      <section className="flex flex-col md:flex-row justify-between mx-4 md:mx-24 xl:mx-48 mb-4 md:mb-0">
        <div
          style={{ backgroundColor: "#E8ECDD" }}
          className="flex justify-center w-full md:w-1/2 mb-8 md:mb-0 mr-0 md:mr-4 py-20"
        >
          <div className="flex flex-col w-8/12 md:w-auto">
            <p className="text-xs mb-2">Start Making Money Now</p>
            <p className="text-2xl font-bookmeBold mb-2">
              Register as Service provider
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://providers.bookme.com.ng/verify-email"
              className="text-bookme-green text-sm"
            >
              Create Provider Account
            </a>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#E8ECDD" }}
          className="flex justify-center w-full md:w-1/2 py-20"
        >
          <div className="flex flex-col w-8/12 md:w-auto">
            <p className="text-xs mb-2">Get Your Jobs Done</p>
            <p className="text-2xl font-bookmeBold mb-2">Register as CLient</p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://client.bookme.com.ng/verify-email"
              className="text-bookme-green text-sm"
            >
              Create Client Account
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Services;
