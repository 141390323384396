/* eslint-disable import/no-cycle */
/* eslint-disable quotes */
import React from "react";
import PropTypes from "prop-types";
import styles from "./Navbar.module.css";
// import styles from '';

const LoginSignUp = ({ type, open, handleClose }) => {
  console.log("");
  return (
    <>
      <div
        style={{ backgroundColor: "#E8ECDD" }}
        className={`inset-0 z-40 flex items-center mt-4 ${
          open ? "fixed z-40" : "hidden"
        } ${styles.LoginSignUp}`}
      >
        <div className="flex flex-col md:flex-row h-64 w-full justify-center">
          <div
            className={`w-full md:w-1/2 border-b-2 md:border-b-0 pb-8 md:pb-0 border-r-0 md:border-r-2 border-gray-500 mb-8 md:mb-0 flex justify-center items-center animate__fast animate__animated ${
              open ? "animate__fadeInDown" : ""
            }`}
          >
            <div className="flex flex-col w-10/12 sm:w-1/2 md:w-auto">
              <p className="text-xs mb-2 font-bookmeMedium">
                Start Making Money Now
              </p>
              <p className="text-2xl font-bookmeBold mb-2">
                {type === "signin"
                  ? "Service Provider Sign In"
                  : "Register as Service Provider"}
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  type === "signin"
                    ? "https://providers.bookme.com.ng/signIn_1"
                    : "https://providers.bookme.com.ng/verify-email"
                }
                className="text-bookme-green text-sm"
              >
                <span>
                  {type === "signin"
                    ? "Login to Provider Account"
                    : "Create Provider Account"}
                </span>
              </a>
            </div>
          </div>
          <div
            className={`w-full md:w-1/2 flex justify-center items-center animate__fast animate__animated ${
              open ? "animate__fadeInDown" : ""
            }`}
          >
            <div className="flex flex-col w-10/12 sm:w-1/2 md:w-auto">
              <p className="text-xs mb-2 font-bookmeMedium">
                Get Your Jobs Done
              </p>
              <p className="text-2xl font-bookmeBold mb-2">
                {type === "signin" ? "Client Sign In" : "Register as Client"}
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  type === "signin"
                    ? "https://client.bookme.com.ng/signIn_1"
                    : "https://client.bookme.com.ng/verify-email"
                }
                className="text-bookme-green text-sm"
              >
                <span>
                  {type === "signin"
                    ? "Login to Client Account"
                    : "Create Client Account"}
                </span>
              </a>
            </div>
          </div>
          <p
            style={{ top: "2rem", right: "2.5rem" }}
            className="absolute text-gray-700 text-4xl cursor-pointer"
            onClick={handleClose}
            aria-hidden
          >
            &times;
          </p>
        </div>
      </div>
    </>
  );
};

LoginSignUp.propTypes = {
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default LoginSignUp;
