import Landing from '../components/Pages/Landing';
import FAQPage from '../components/Pages/FAQPage';
import Services from '../components/Pages/Services';

const routes = [
  {
    path: '/',
    component: Landing,
    exact: true,
  },
  {
    path: '/faqs',
    component: FAQPage,
    exact: true,
  },
  {
    path: '/services',
    component: Services,
    exact: true,
  },
];

export default routes;
