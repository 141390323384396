/* eslint-disable quotes */
import React, { useState, useEffect } from "react";
import BookMeLogo from "../../assets/BookMeLogo.svg";
// Images & SVGs
import InstagramIcon from "../../assets/instagram-black.svg";
import FacebookIconBlack from "../../assets/facebook-black.svg";
import TwitterIcon from "../../assets/twitter-black.svg";
// CSS
import styles from "./FAQItem.module.css";

const Footer = () => {
  const [year, setYear] = useState(2020);

  // ON MOUNT
  useEffect(() => {
    const currentDate = new Date();
    setYear(currentDate.getFullYear());
  }, []);
  return (
    <div className="px-4 py-4 md:px-24 xl:px-48 md:py-20 text-gray-800">
      <section
        style={{ borderBottom: "1px solid #CCCCCC" }}
        className="flex flex-col md:flex-row pb-4 md:pb-12 mb-8"
      >
        <div className="flex flex-col flex-1 md:mr-8 mb-8 md:mb-0">
          <img
            className="mb-8 w-5/12 self-center md:self-auto"
            src={BookMeLogo}
            alt="bookme_logo"
          />
          <p className="text-sm mb-8 w-11/12 md:w-full self-center md:self-auto text-justify">
            BookMe connects you to professional service providers who ensure the
            smooth running of your business from the comfort of your home or
            office.
          </p>
          <div className="flex items-center justify-center md:justify-start">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/BookMe-Nigeria-113036617105477"
            >
              <img
                style={{ width: "17px", height: "17px" }}
                src={FacebookIconBlack}
                className="mr-6"
                alt="facebook_icon"
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/bookmenigeria/?hl=en"
            >
              <img
                style={{ width: "17px", height: "17px" }}
                src={InstagramIcon}
                className="mr-6"
                alt="instagram_icon"
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/BookMeNigeria"
            >
              <img
                style={{ width: "17px", height: "17px" }}
                src={TwitterIcon}
                alt="twitter_icon"
              />
            </a>
          </div>
        </div>
        <div className="flex flex-col flex-1 mb-8 md:mb-0 md:mx-12 items-center md:items-start footer_company">
          <p className="font-bookmeMedium text-lg mb-8 ff">THE COMPANY</p>
          {/* <div className="flex sm:flex-row"></div> */}
          <p className="mb-3 text-sm">About BookMe</p>
          <p className="mb-3 text-sm ">Contact Us</p>
          <p className="mb-3 text-sm ">Terms of Service</p>
          <p className="mb-3 text-sm">Services</p>
        </div>
        <div
          className={`flex flex-col flex-1 md:ml-8 items-center md:items-start ${styles.footer_contact}`}
        >
          <p className="font-bookmeMedium text-lg mb-2">CONTACT</p>
          <p className="mb-3 text-sm">
            3, New Creations Street, Marwa Junction Lekki, Lagos, Nigeria.
          </p>
          <p className="mb-3 text-sm">
            <span>
              <a href="tel:+2347085259874">07085259874, </a>
              <a href="tel:+2347049211186">07049211186, </a>
              <a href="tel:+2349050702617">09050702617</a>
            </span>
          </p>
          <p className="mb-3 text-sm">hello@bookme.com.ng</p>
        </div>
      </section>
      <p className="text-sm">{`Copyright © ${year} BookMe. All Rights Reserved`}</p>
    </div>
  );
};

export default Footer;
