import React from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import routes from './routes';
import withTracker from '../components/withTracker';

const CustomRouter = () => {
  console.log('object');
  return (
    <Router>
      <Switch>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={withTracker(route.component)}
            // render={() => withTracker(<route.component key={route.path} />)}
          />
        ))}
      </Switch>
    </Router>
  );
};

export default CustomRouter;
