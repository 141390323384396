/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
/* eslint-disable max-len */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Components
//import Navbar from "../Shared/Navbar";
import Header from "../Shared/header";
import Footer from "../Shared/Footer";
import FAQItem from "../Shared/FAQItem";
import FAQContainer from "../Shared/FAQContainer";

// Images & SVGs
import ServiceProviderImage from "../../assets/hero-client.png";
import ClientImage from "../../assets/client.jpg";
import LSETF from "../../assets/LSETF-NEW.png";
import InstagramIcon from "../../assets/instagram.svg";
import FacebookIcon from "../../assets/facebook.svg";
import TwitterIcon from "../../assets/twitter.svg";
import HeroOne from "../../assets/hero-1.svg";
import HeroTwo from "../../assets/hero-2.svg";
import BarberImage from "../../assets/barber.png";
import PainterImage from "../../assets/painter.png";
import CobblerImage from "../../assets/cobbler.png";
import CarpenterImage from "../../assets/carpenter.png";
import chevronRightBlack from "../../assets/chevron-right-black.svg";
import chevronRightGreen from "../../assets/chevron-right-green.svg";
import CaretIcon from "../../assets/caret.svg";
import AppStore from "../../assets/appstore.png";
import GooglePlay from "../../assets/google.png";

// Style
import styles from "./Landing.module.css";

/** COMPONENT FOR INDIVIDUAL SERVICE CARDS */
const ServiceImage = ({ title, image, url }) => (
  <div className="sm:w-1/2 md:w-3/12">
    <div className="flex flex-col m-4">
      <a href={url} target="_blank" rel="noreferrer">
        <img className="mb-2" src={image} alt={title} />
      </a>
      <p
        style={{ borderColor: "#DFE6CE", borderBottomWidth: "1px" }}
        className="justify-between flex flex-row pb-2 relative"
      >
        <span className="ml-7">{title}</span>
        <img
          src={chevronRightBlack}
          alt="chevron_right"
          className={`${styles.serviceimage}`}
        />
      </p>
    </div>
  </div>
);

const Landing = () => {
  const history = useHistory();

  const AppleStore = {
    client: "https://apps.apple.com/us/app/bookme-ng-client/id1548361420",
    provider: "https://apps.apple.com/us/app/bookme-ng-business/id1541176753",
  };

  const PlayStore = {
    client: "https://play.google.com/store/apps/details?id=com.bookme.client",
    provider: "https://play.google.com/store/apps/details?id=com.bookme.sp",
  };

  const socialLinks = {
    faceBook: "https://www.facebook.com/BookMeNigeria/",
    instaGram: "https://www.instagram.com/bookmenigeria/",
    twitter: "https://twitter.com/BookMeNigeria",
  };

  const [currentView, setCurrentView] = useState("client");
  const [bannerImage, setBannerImage] = useState(ServiceProviderImage);
  const [activeFAQ, setActiveFAQ] = useState("");
  const [toAppStore, setToAppStore] = useState(AppleStore.client);
  const [toPlayStore, setToPlayStore] = useState(PlayStore.client);

  /** CHANGE THE VIEW BASED ON USERS CLICK */
  const handleChangeView = (view) => {
    if (view === "serviceProvider") {
      setCurrentView("serviceProvider");
      setBannerImage(ServiceProviderImage);
      setToAppStore(AppleStore.provider);
      setToPlayStore(PlayStore.provider);
    }

    if (view === "client") {
      setBannerImage(ClientImage);
      setCurrentView("client");
      setToAppStore(AppleStore.client);
      setToPlayStore(PlayStore.client);
    }
  };

  /** HANDLE FAQ CLICK */
  const handleClickFAQ = (title) => {
    setActiveFAQ(title);
  };

  return (
    <div className={`flex flex-col ${styles.body}`}>
      {/*<Navbar />*/}
      <Header />
      <div
        style={{ backgroundImage: `url(${bannerImage})` }}
        className={`bg-indigo-500 ${styles.banner} relative justify-center flex px-8 md:px-24 xl:px-48  flex-col md:pt-20 pt-32 md:h-screen`}
      >
        {/*  socials  */}
        <div
          className={`flex flex-col hidden justify-around md:flex ${styles.social_absolute}`}
        >
          <a href={socialLinks.faceBook} target="_blank" rel="noreferrer">
            <img
              src={FacebookIcon}
              className={`${styles.socialMedia} mb-4 cursor-pointer mr-6`}
              alt="facebook_icon"
            />
          </a>
          <a href={socialLinks.instaGram} target="_blank" rel="noreferrer">
            <img
              src={InstagramIcon}
              className={`${styles.socialMedia} mb-4 cursor-pointer mr-6`}
              alt="instagram_icon"
            />
          </a>
          <a href={socialLinks.twitter} target="_blank" rel="noreferrer">
            <img
              src={TwitterIcon}
              className={`${styles.socialMedia} cursor-pointer`}
              alt="twitter_icon"
            />
          </a>
        </div>
        {/*  socials */}
        {/* lets try */}
        <div className={`flex z-10 pt-2.5 ${styles.provider_medium}`}>
          <button
            onClick={() => handleChangeView("client")}
            type="button"
            className={` bg-white relative h-10 text-sm font-bookmeMedium w-40 outline-none focus:outline-none ${
              currentView === "client" && "border-b-4 bg-opacity-100"
            } bg-opacity-50 border-bookme-green animate__animated animate__shakeX animate__delay-5s animate__slow`}
          >
            <span>Client</span>
            {currentView === "client" && (
              <img
                style={{ bottom: "-13.6px" }}
                src={CaretIcon}
                alt="caret"
                className={`absolute right-0 left-0 mx-auto ${styles.caret}`}
              />
            )}
          </button>
          <button
            onClick={() => handleChangeView("serviceProvider")}
            type="button"
            className={`bg-white relative h-10 text-sm font-bookmeMedium w-40 outline-none focus:outline-none ${
              currentView === "serviceProvider" && "border-b-4 bg-opacity-100"
            } bg-opacity-50 border-bookme-green animate__animated animate__shakeX animate__delay-2s animate__slow`}
          >
            <span>
              Service Provider
              {currentView === "serviceProvider" && (
                <img
                  style={{ bottom: "-13.6px" }}
                  src={CaretIcon}
                  alt="caret"
                  className={`absolute right-0 left-0 mx-auto ${styles.caret}`}
                />
              )}
            </span>
          </button>
        </div>
        {/* lets try */}
        <div
          data-container
          className={`z-10 text-white mb-24 md:mb-0 flex flex-col justify-center flex-grow w-full md:w-7/12 ${styles.banner_content} `}
        >
          <p className=" font-bookmeBold text-bookme-green text-xs">
            {currentView === "serviceProvider" ? "SERVICE PROVIDER" : "CLIENT"}
          </p>
          <p className=" font-bookmeBold text-5xl mb-8">
            {currentView === "serviceProvider"
              ? "Offer your services and get paid"
              : "Get Your Jobs Done As Soon As Possible"}
          </p>
          <p className="font-bookmeMedium text-sm mb-8">
            {currentView === "serviceProvider"
              ? "Showcase your skills, manage your business and reach more clients on BookMe."
              : "Get the best workers closest to you in an efficient manner to handle your jobs"}
          </p>
          <div className={`flex ${styles.banner_link}`}>
            <a
              target="_blank"
              rel="noreferrer"
              href={
                currentView === "serviceProvider"
                  ? "https://providers.bookme.com.ng/"
                  : "https://client.bookme.com.ng/"
              }
              type="button"
              className={`border mr-4 px-8 py-3 rounded text-sm md:text-lg font-bookmeBold ${styles.learnl}`}
            >
              Learn More
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href={
                currentView === "serviceProvider"
                  ? "https://providers.bookme.com.ng/verify-email"
                  : "https://client.bookme.com.ng/verify-email"
              }
              className={`px-8 py-3 bg-bookme-green rounded text-sm md:text-lg font-bookmeBold ${styles.servicel}`}
            >
              {currentView === "serviceProvider"
                ? "Become a Service Provider"
                : "Become a Client"}
            </a>
          </div>
        </div>
        <div className={`flex flex-col h-16 ${styles.banner_socials}`}>
          <div
            data-bannerbottom
            className={`flex h-full relative justify-self-center justify-between z-10 ${styles.banner_socials}`}
          >
            <div className={`flex flex-row justify-between ${styles.partner}`}>
              <p className="font-bookmeMedium text-sm text-white mr-8 mt-2">
                Official partner of LSETF
              </p>
              <img src={LSETF} className="h-10" alt="lsetf_logo" />
            </div>
            {/* change below to google play store */}
            <div
              className={`flex flex-row flex-end z-10 items-center pb-6 ${styles.store}`}
            >
              <a href={toAppStore} target="_blank" rel="noreferrer">
                <img
                  src={AppStore}
                  className={`cursor-pointer mr-6 ${styles.apple} animate__animated animate__shakeY`}
                  alt="appstore_icon"
                />
              </a>
              <a href={toPlayStore} target="_blank" rel="noreferrer">
                <img
                  src={GooglePlay}
                  className={`cursor-pointer mr-6 ${styles.google} animate__animated animate__shakeY`}
                  alt="googleplay_icon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <section
        style={{ backgroundColor: "#E8ECDD" }}
        className="px-4 md:px-24 xl:px-48 flex flex-col"
      >
        <div className="flex flex-col md:flex-row justify-between py-12">
          <div className="w-full md:w-2/5 flex items-center mb-8 md:mb-0">
            <img
              src={HeroOne}
              className="w-full"
              style={{ height: "300px" }}
              alt="hero_image"
            />
          </div>
          <div className="w-full md:w-2/5 flex flex-col justify-center">
            <p className="font-bookmeBold text-4xl mb-8 leading-10">
              BookMe connects Service Providers and Clients
            </p>
            <p className="font-bookmeBook text-sm">
              BookMe is a professionally designed lead magnet, sales booster and
              brand-differentiating website built for your small and medium
              business to strategically target customers and get patronized.
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between py-12">
          <div className="w-full md:w-2/5 flex items-center mb-8 md:mb-0 md:order-2">
            <img
              src={HeroTwo}
              className="w-full"
              style={{ height: "300px" }}
              alt="hero_image"
            />
          </div>
          <div className="w-full md:w-2/5 flex flex-col justify-center md:order-1">
            <p className="font-bookmeBold text-4xl mb-8 leading-10">
              Maximum security guaranteed
            </p>
            <p className="font-bookmeBook text-sm">
              BookMe ensures a comfortable and secure platform for your business
              allowing you focus on transacting with customers with zero worries
            </p>
          </div>
        </div>
      </section>

      <section className="flex flex-col px-4 py-4 md:px-24 xl:px-48 md:py-12 relative">
        <div className="w-full md:w-6/12 mb-12">
          <p className="font-bookmeBold w-full text-2xl md:text-4xl mb-4 leading-10">
            Over 1,000 Services to choose from
          </p>
          <p
            aria-hidden
            onClick={() => history.push("/services")}
            className="text-bookme-green text-lg flex cursor-pointer relative"
          >
            <span className="mr-4">View all services</span>
            <img
              src={chevronRightGreen}
              alt="chevron_right"
              className={`${styles.chevronRightGreen}`}
            />
          </p>
        </div>
        <div className="flex flex-wrap">
          <ServiceImage
            image={BarberImage}
            title="Barber"
            url="https://client.bookme.com.ng/providers?state=5ebeb7ec7d361c7dca0aac1c&&category=barbers&&location=Lagos"
          />
          <ServiceImage
            image={CobblerImage}
            title="Cobbler"
            url="https://client.bookme.com.ng/providers?state=5ebeb7ec7d361c7dca0aac1c&&category=boot%20and%20shoe%20makers&&location=Lagos"
          />
          <ServiceImage
            image={PainterImage}
            title="Interior Painter"
            url="https://client.bookme.com.ng/providers?state=5ebeb7ec7d361c7dca0aac1c&&category=painters&&location=Lagos"
          />
          <ServiceImage
            image={CarpenterImage}
            title="Carpenter"
            url="https://client.bookme.com.ng/providers?state=5ebeb7ec7d361c7dca0aac1c&&category=carpenters&&location=Lagos"
          />
        </div>
      </section>

      <section className="px-4 py-4 md:px-24 xl:px-48 md:py-12">
        <div className={`w-full md:w-6/12 mb-12 relative ${styles.FAQs}`}>
          <p className="font-bookmeBold w-10/12 text-4xl mb-0 md:mb-4 leading-10">
            Frequently asked questions
          </p>
          <p
            aria-hidden
            onClick={() => history.push("/faqs")}
            className="text-bookme-green text-lg flex items-center cursor-pointer relative"
          >
            <span className="mr-4">View all FAQs</span>
            <img
              src={chevronRightGreen}
              alt="chevron_right"
              className={`${styles.chevronFAQ}`}
            />
          </p>
        </div>
        <div className="flex flex-col">
          <FAQContainer activeFAQ={activeFAQ}>
            <FAQItem
              handleClickFAQ={handleClickFAQ}
              open={false}
              title="IS YOUR WEB APPLICATION MOBILE RESPONSIVE?"
              content="Yes, BookMe‘s web application is 100% mobile responsive and user friendly. BookMe also has a mobile application which can be gotten from your android play store. With our user friendly mobile application, you get to leave reviews on services received and connect with verified service providers"
            />
            <FAQItem
              handleClickFAQ={handleClickFAQ}
              open={false}
              title="IS THERE A LIMIT ON THE SERVICES THAT I CAN BOOK?"
              content="There is NO limit on the number of services you have access to or can book. With BookMe, you get to enjoy the freedom to book at any hour of any day."
            />
            <FAQItem
              handleClickFAQ={handleClickFAQ}
              open={false}
              title="IF I CHOOSE TO LEAVE, WHAT HAPPENS TO MY DATA?"
              content="You own your data, our job is to keep it safe and secure while you are with us. We only retain your data in backup for 30 days and no more than that."
            />
            <FAQItem
              handleClickFAQ={handleClickFAQ}
              open={false}
              title="WHAT ARE YOUR SECURITY PRACTICES?"
              content="Security is our first and foremost consideration. Each account comes with an SSL Certificate and includes advanced security scanning and firewall. Every account operates in a segregated database environment."
            />
            <FAQItem
              handleClickFAQ={handleClickFAQ}
              open={false}
              title="HOW DO I USE BOOKME TO BOOK A SERVICE?"
              content="BookMe offers our ready-to-go hosted booking page where you can explore booking options and in-app offers at any hour of the day."
            />
            <FAQItem
              handleClickFAQ={handleClickFAQ}
              open={false}
              title="WHERE IS THE SERVICE HOSTED?"
              content="BookMe operates on a decentralized group of geographically diverse data centres. We pick a service location that operates closest to your business. This allows us to provide you with the most reliable service possible."
            />
            <FAQItem
              handleClickFAQ={handleClickFAQ}
              open={false}
              title="WHAT DO I NEED TO UNDERSTAND ABOUT THE BOOKING PROCESS?"
              content={
                <>
                  <span>
                    Follow these guidelines for a seamless booking experience on
                    BookMe:
                  </span>
                  <br />
                  <br />
                  <ol
                    style={{ listStyleType: "decimal", marginLeft: "32px" }}
                    type="1"
                  >
                    <li>
                      Before booking, ensure the service provider you need is
                      available for booking.
                    </li>
                    <br />
                    <li>
                      When booking, reach out to only verified BookMe service
                      providers
                    </li>
                    <br />
                    <li>
                      After booking, follow the steps to our third party payment
                      option and pay for pre-booked services.
                    </li>
                  </ol>
                </>
              }
            />
          </FAQContainer>
        </div>
      </section>

      {/* <section className="flex flex-col items-center mb-12 px-4 py-4 md:px-24 xl:px-48">
        <div className=" w-11/12 md:w-5/12 text-center">
          <p className=" font-bookmeBold text-3xl mb-8">Subscribe to our Newsletter</p>
          <p className=" text-gray-800 mb-8">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the
          </p>
        </div>
        <div className="md:h-12 flex flex-col md:flex-row w-full justify-center">
          <input
          style={{ border: '1px solid #E1E1E1' }}
          type="text" placeholder="Enter your email"
          className=" h-12 mb-4 md:mb-0 md:h-full w-full md:w-1/2" />
          <button type="button"
          className="bg-bookme-green md:h-full px-4 py-2 md:py-0 text-white">Subscribe</button>
        </div>
      </section> */}

      <section className="flex flex-col md:flex-row justify-between mx-4 md:mx-24 xl:mx-48 mb-4 md:mb-0">
        <div
          style={{ backgroundColor: "#E8ECDD" }}
          className="flex justify-center w-full md:w-1/2 mb-8 md:mb-0 mr-0 md:mr-4 py-20"
        >
          <div className="flex flex-col w-8/12 md:w-auto">
            <p className="text-xs mb-2">Start Making Money Now</p>
            <p className="text-2xl font-bookmeBold mb-2">
              Register as Service provider
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://providers.bookme.com.ng/verify-email"
              className="text-bookme-green text-sm"
            >
              Create Provider Account
            </a>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#E8ECDD" }}
          className="flex justify-center w-full md:w-1/2 py-20"
        >
          <div className="flex flex-col w-8/12 md:w-auto">
            <p className="text-xs mb-2">Get Your Jobs Done</p>
            <p className="text-2xl font-bookmeBold mb-2">Register as CLient</p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://client.bookme.com.ng/verify-email"
              className="text-bookme-green text-sm"
            >
              Create Client Account
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Landing;
