/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unknown-property */
/* eslint-disable quotes */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./Navbar.module.css";
import BookMeLogo from "../../assets/BookMeLogo.svg";
import UserIcon from "../../assets/user.svg";
import LoginSignUp from "./LoginSignUp";

function Header() {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("signin");
  const [openLoginSignUp, setOpenLoginSignUp] = useState(false);

  const history = useHistory();

  const toggleNavbar = () => {
    setOpen(!open);
  };

  const handleShowModalPage = () => {
    setOpenLoginSignUp(true);
  };

  const handleModalPageClose = (typeToDisplay) => {
    setType(typeToDisplay);
    setOpenLoginSignUp(false);
  };

  useEffect(() => {
    const button = document.querySelector("#menu-button");
    const menu = document.querySelector("#menu");

    button.addEventListener("click", () => {
      menu.classList.toggle("hidden");
    });
  }, []);

  return (
    <header>
      <nav
        className={`flex flex-wrap
          items-center
          justify-between
          w-full
          py-4
          md:py-0
          px-4
          text-lg text-gray-700
          bg-white fixed top-0 z-50 ${styles.navBg}`}
      >
        <div>
          <a href="/">
            <img
              src={BookMeLogo}
              className="cursor-pointer xl:ml-40"
              alt="bookme_logo"
              onClick={toggleNavbar}
            />
          </a>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="menu-button"
          className="h-6 w-6 cursor-pointer md:hidden block"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={toggleNavbar}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>

        <div
          className="hidden w-full md:flex md:items-center md:w-auto xl:mr-40"
          id="menu"
        >
          <ul
            className="
              pt-4
              text-base text-gray-700
              md:flex
              md:justify-between 
              md:pt-0 xl:mr-32"
          >
            <li>
              <a
                href="https://www.datainfosec.net"
                target="_blank"
                rel="noreferrer"
              >
                <p className="font-bookmeMedium md:p-4 py-2 block hover:text-black cursor-pointer xl:hover:bg-bookme-green md:hover:bg-transparent md:hover:text-black">
                  Company
                </p>
              </a>
            </li>
            <li>
              <p className="font-bookmeMedium md:p-4 py-2 block hover:text-black cursor-pointer xl:hover:bg-bookme-green md:hover:bg-transparent md:hover:text-black">
                Blog
              </p>
            </li>
            <li>
              <p
                className="font-bookmeMedium md:p-4 py-2 block hover:text-black cursor-pointer xl:hover:bg-bookme-green md:hover:bg-transparent md:hover:text-black"
                onClick={() => history.push("/faqs")}
              >
                FAQs
              </p>
            </li>
            <li>
              <p
                className="font-bookmeMedium md:p-4 py-2 block hover:text-black cursor-pointer xl:hover:bg-bookme-green md:hover:bg-transparent md:hover:text-black"
                onClick={() => history.push("/services")}
              >
                Services
              </p>
            </li>
          </ul>
          <button
            className={`${styles.login} font-bookmeMedium md:p-4 py-2 block  md:rounded mr-2 md:py-2 py-0 md:px-6 px-0  flex items-center md:hover:bg-bookme-green bg-transparent md:hover:text-white cursor-pointer`}
            type="button"
            onClick={() => {
              handleShowModalPage("signin");
              toggleNavbar();
            }}
          >
            <img
              style={{ width: "14px", height: "14px" }}
              src={UserIcon}
              alt="user_icon"
              className="mr-2"
            />
            <span>Login</span>
          </button>
          <button
            className={`font-bookmeMedium py-2 mr-12 px-6 xl:py-2 md:p-4 py-2 block rounded bg-bookme-green hover:bg-green-700 text-white cursor-pointer ${styles.signUpButton}`}
            type="button"
            onClick={() => handleShowModalPage("signup")}
          >
            Sign up
          </button>
        </div>
      </nav>
      <LoginSignUp
        type={type}
        open={openLoginSignUp}
        handleClose={handleModalPageClose}
      />
    </header>
  );
}

export default Header;
