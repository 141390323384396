/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
import React from "react";
import propTypes from "prop-types";

// Images && Icons
import chevronRightBlack from "../../assets/chevron-right-black.svg";

// CSS
import styles from "./FAQItem.module.css";

/** FAQ COMPONENT */

const FAQItem = ({ title, open, handleClickFAQ, content }) => {
  // console.log(content, "c");
  const handleClick = () => {
    handleClickFAQ(title);
  };

  return (
    <div style={{ backgroundColor: "#E8ECDD" }} className="flex flex-col my-2">
      <div
        role="button"
        tabIndex={-1}
        onKeyPress={() => {}}
        onClick={handleClick}
        className="flex justify-between pl-8 pr-16 py-6 font-bookmeMedium outline-none cursor-pointer"
      >
        <p>{title}</p>
        <img src={chevronRightBlack} alt="chevron_right" />
      </div>
      <div
        className={`pl-8 pr-16  ${
          open ? `${styles.open}` : `${styles.close} py-0`
        }`}
      >
        {content}
      </div>
    </div>
  );
};

FAQItem.defaultProps = {
  content: "H",
};

FAQItem.propTypes = {
  title: propTypes.string.isRequired,
  open: propTypes.bool.isRequired,
  content: propTypes.oneOfType([
    propTypes.string,
    propTypes.objectOf({}),
    propTypes.element,
  ]),
  handleClickFAQ: propTypes.func.isRequired,
};

export default FAQItem;
