import React from 'react';
import CustomRouter from './routers/CustomRouter';
import './styles/main.css';
import 'animate.css';

function App() {
  return (
    <div className="App">
      <CustomRouter />
    </div>
  );
}

export default App;
