/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable quotes */
import React, { useState } from "react";

// Components
// import Navbar from "../Shared/Navbar";
import Header from "../Shared/header";
import FAQItem from "../Shared/FAQItem";
import Footer from "../Shared/Footer";
import FAQContainer from "../Shared/FAQContainer";

const activeText = {
  all: "All",
  serviceProviders: "Service Providers",
  clients: "Client",
};

const FAQPage = () => {
  const [active, setActive] = useState("all");
  const [activeFAQ, setActiveFAQ] = useState("");

  /** SET ACTIVE VIEW */
  const handleSetActive = (view) => {
    setActive(view);
  };

  const handleClickFAQ = (title) => {
    console.log(title);
    setActiveFAQ(title);
  };

  return (
    <div className="flex flex-col">
      {/* <Navbar /> */}
      <Header />
      <section
        style={{ backgroundColor: "#E8ECDD" }}
        className="flex px-4 py-16 md:pt-32 md:pb-16 md:px-24 xl:px-48 mb-4 md:mb-0"
      >
        <div className="w-full md:w-1/2">
          <p className="font-bookmeBold w-10/12 text-4xl leading-10">
            Frequently Asked Questions
          </p>
        </div>
      </section>

      <section className="flex flex-col md:flex-row px-4 py-4 md:px-24 xl:px-48 md:py-16">
        <div className="flex flex-row md:flex-col w-full md:w-1/4 justify-around md:justify-start mb-8 md:mb-0 font-bookmeBold">
          <button
            type="button"
            onClick={() => handleSetActive("all")}
            className={`md:mb-6 text-center md:text-left outline-none focus:outline-none ${
              active === "all" ? "text-bookme-green" : "text-black"
            }`}
          >
            All
          </button>
          <button
            type="button"
            onClick={() => handleSetActive("serviceProviders")}
            className={`md:mb-6 text-center md:text-left outline-none focus:outline-none ${
              active === "serviceProviders" ? "text-bookme-green" : "text-black"
            }`}
          >
            Service Provider
          </button>
          <button
            type="button"
            onClick={() => handleSetActive("clients")}
            className={`outline-none text-center md:text-left focus:outline-none ${
              active === "clients" ? "text-bookme-green" : "text-black"
            }`}
          >
            Client
          </button>
        </div>
        <div className="w-full md:w-3/4">
          <p className="font-bookmeBold text-2xl leading-10 mb-4">
            {`${activeText[active]} FAQs`}
          </p>
          {active === "serviceProviders" && (
            <p className="mb-8">
              See answers to all Frequently Asked Questions by Service Providers
            </p>
          )}
          {active === "clients" && (
            <p className="mb-8">
              See answers to all Frequently Asked Questions by Clients
            </p>
          )}
          {active === "all" && (
            <p className="mb-8">
              See answers to all Frequently Asked Questions
            </p>
          )}
          <div className="flex flex-col">
            <FAQContainer activeFAQ={activeFAQ}>
              {(active === "serviceProviders" || active === "all") && (
                <FAQItem
                  open={false}
                  handleClickFAQ={handleClickFAQ}
                  title="IS YOUR WEB APPLICATION MOBILE RESPONSIVE?"
                  content="Yes, BookMe‘s web application is 100% mobile responsive and user friendly. BookMe also has a mobile application which can be gotten from your android play store. With our user friendly mobile application, you get to leave reviews on services received and connect with verified service providers"
                />
              )}
              {(active === "clients" || active === "all") && (
                <FAQItem
                  open={false}
                  handleClickFAQ={handleClickFAQ}
                  title="IS THERE A LIMIT ON THE SERVICES THAT I CAN BOOK?"
                  content="There is NO limit on the number of services you have access to or can book. With BookMe, you get to enjoy the freedom to book at any hour of any day."
                />
              )}
              {(active === "serviceProviders" || active === "all") && (
                <FAQItem
                  open={false}
                  handleClickFAQ={handleClickFAQ}
                  title="IF I CHOOSE TO LEAVE, WHAT HAPPENS TO MY DATA?"
                  content="You own your data, our job is to keep it safe and secure while you are with us. We only retain your data in backup for 30 days and no more than that."
                />
              )}
              {(active === "clients" || active === "all") && (
                <FAQItem
                  open={false}
                  handleClickFAQ={handleClickFAQ}
                  title="WHAT ARE YOUR SECURITY PRACTICES?"
                  content="Security is our first and foremost consideration. Each account comes with an SSL Certificate and includes advanced security scanning and firewall. Every account operates in a segregated database environment."
                />
              )}
              {(active === "serviceProviders" || active === "all") && (
                <FAQItem
                  open={false}
                  handleClickFAQ={handleClickFAQ}
                  title="HOW DO I USE BOOKME TO BOOK A SERVICE?"
                  content="BookMe offers our ready-to-go hosted booking page where you can explore booking options and in-app offers at any hour of the day."
                />
              )}
              {(active === "serviceProviders" || active === "all") && (
                <FAQItem
                  open={false}
                  handleClickFAQ={handleClickFAQ}
                  title="WHERE IS THE SERVICE HOSTED?"
                  content="BookMe operates on a decentralized group of geographically diverse data centres. We pick a service location that operates closest to your business. This allows us to provide you with the most reliable service possible."
                />
              )}
              {(active === "clients" || active === "all") && (
                <FAQItem
                  handleClickFAQ={handleClickFAQ}
                  title="WHAT DO I NEED TO UNDERSTAND ABOUT THE BOOKING PROCESS?"
                  content={
                    <>
                      <span>
                        Follow these guidelines for a seamless booking
                        experience on BookMe:
                      </span>
                      <br />
                      <br />
                      <ol
                        style={{ listStyleType: "decimal", marginLeft: "2rem" }}
                        type="1"
                      >
                        <li>
                          Before booking, ensure the service provider you need
                          is available for booking.
                        </li>
                        <br />
                        <li>
                          When booking, reach out to only verified BookMe
                          service providers
                        </li>
                        <br />
                        <li>
                          After booking, follow the steps to our third party
                          payment option and pay for pre-booked services.
                        </li>
                      </ol>
                    </>
                  }
                />
              )}
            </FAQContainer>
          </div>
        </div>
      </section>

      {/* <section className="flex flex-col items-center mb-12 px-4 py-4 md:px-24 xl:px-48">
        <div className=" w-11/12 md:w-5/12 text-center">
          <p className=" font-bookmeBold text-3xl mb-8">Subscribe to our Newsletter</p>
          <p className=" text-gray-800 mb-8">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the
          </p>
        </div>
        <div className="md:h-12 flex flex-col md:flex-row w-full justify-center">
          <input
            style={{ border: '1px solid #E1E1E1' }}
            type="text"
            placeholder="Enter your email"
            className=" h-12 mb-4 md:mb-0 md:h-full w-full md:w-1/2"
          />
          <button
            type="button"
            className="bg-bookme-green md:h-full px-4 py-2 md:py-0 text-white"
          >
            Subscribe

          </button>
        </div>
      </section> */}

      <section className="flex flex-col md:flex-row justify-between mx-4 md:mx-24 xl:mx-48 mb-4 md:mb-0">
        <div
          style={{ backgroundColor: "#E8ECDD" }}
          className="flex justify-center w-full md:w-1/2 mb-8 md:mb-0 mr-0 md:mr-4 py-20"
        >
          <div className="flex flex-col w-8/12 md:w-auto">
            <p className="text-xs mb-2">Start Making Money Now</p>
            <p className="text-2xl font-bookmeBold mb-2">
              Register as Service provider
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://providers.bookme.com.ng/verify-email"
              className="text-bookme-green text-sm"
            >
              Create Provider Account
            </a>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#E8ECDD" }}
          className="flex justify-center w-full md:w-1/2 py-20"
        >
          <div className="flex flex-col w-8/12 md:w-auto">
            <p className="text-xs mb-2">Get Your Jobs Done</p>
            <p className="text-2xl font-bookmeBold mb-2">Register as CLient</p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://client.bookme.com.ng/verify-email"
              className="text-bookme-green text-sm"
            >
              Create Client Account
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default FAQPage;
