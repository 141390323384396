/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-fragments */
import propTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";

const FAQContainer = ({ children, activeFAQ }) => {
  const [toShow, setToShow] = useState([]);
  useEffect(() => {
    const data = children.filter((child) => child !== false);
    setToShow(data);
  }, [children]);

  const renderFAQ = () =>
    toShow.map((item) => {
      const itemComp = item;
      if (itemComp.props.title === activeFAQ) {
        return React.cloneElement(itemComp, {
          open: true,
          key: itemComp.props.title,
        });
      }
      return React.cloneElement(itemComp, {
        open: false,
        key: itemComp.props.title,
      });
    });

  return <Fragment>{renderFAQ()}</Fragment>;
};

FAQContainer.propTypes = {
  children: propTypes.arrayOf(propTypes.element).isRequired,
  activeFAQ: propTypes.string.isRequired,
};

export default FAQContainer;
